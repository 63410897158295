<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!purchasesOrder">
      <span class="loader"></span>
    </span>
    <div v-if="purchasesOrder" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="details"
      >
        <tab-pane title="global" id="1" :active="false">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <purchases-order-view-global :purchasesOrder="purchasesOrder" />
        </tab-pane>

        <tab-pane title="details" id="2" :active="false">
          <span slot="title">
            <i class="ni ni-bullet-list-67"></i>
            {{ $t("COMMON.DETAILS") }}
          </span>
          <purchases-order-view-details
            :purchasesOrder="purchasesOrder"
            @purchasesOrderItemsUpdated="get"
          />
        </tab-pane>

        <tab-pane title="details" id="3" :active="false">
          <span slot="title">
            <i class="ni ni-single-copy-04"></i>
            {{ $t("PURCHASES_ORDERS.PURCHASES_INVOICES") }}
          </span>
          <purchases-order-view-invoices
            :purchasesOrder="purchasesOrder"
            @purchasesOrderInvoicesUpdated="get"
          />
        </tab-pane>

        <tab-pane title="details" id="4" :active="false">
          <span slot="title">
            <i class="fa fa-truck"></i>
            {{ $t("PURCHASES_ORDERS.DELIVERIES_STATE") }}
          </span>
          <purchases-order-view-delivery-state
            :purchasesOrder="purchasesOrder"
            @purchasesOrderDeliveiesUpdated="get"
          />
        </tab-pane>

        <tab-pane title="details" id="5" :active="false">
          <span slot="title">
            <i class="fa fa-truck"></i>
            {{ $t("COMMON.PURCHASES_DELIVERIES") }}
          </span>
          <purchases-order-view-deliveries
            :purchasesOrder="purchasesOrder"
            @purchasesOrderDeliveiesUpdated="get"
          />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
            purchasesOrder.organization
          "
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="purchasesOrder" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <purchases-order-view-logs :purchasesOrder="purchasesOrder" />
        </tab-pane>
      </tabs>
      <purchases-order-view-add-delivery-form
        v-if="!purchasesOrder.deliveryItemsState.delivered"
        :purchasesOrder="purchasesOrder"
        :showModal="purchasesOrderDeliveryFormModalOpened"
        @closePourchasesOrderDeliveryForm="
          () => {
            purchasesOrderDeliveryFormModalOpened = false;
          }
        "
      />
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import {
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_DRAFT,
  ORDER_STATUS_VALIDATED,
} from "@/constants/orders";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import PurchasesOrderViewGlobal from "../partials/PurchasesOrderViewGlobal.vue";
import PurchasesOrderViewDetails from "../partials/PurchasesOrderViewDetails.vue";
import PurchasesOrderViewLogs from "../partials/PurchasesOrderViewLogs.vue";
import PurchasesOrderViewAddDeliveryForm from "../partials/PurchasesOrderViewAddDeliveryForm.vue";
import PurchasesOrderViewDeliveries from "../partials/PurchasesOrderViewDeliveries.vue";
import PurchasesOrderViewDeliveryState from "../partials/PurchasesOrderViewDeliveryState.vue";
import PurchasesOrderViewInvoices from "../partials/PurchasesOrderViewInvoices.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    PurchasesOrderViewGlobal,
    PurchasesOrderViewDetails,
    PurchasesOrderViewLogs,
    ListFileComponent,
    PurchasesOrderViewAddDeliveryForm,
    PurchasesOrderViewDeliveries,
    PurchasesOrderViewDeliveryState,
    PurchasesOrderViewInvoices,
  },

  mixins: [requestErrorMixin],

  props: {
    purchasesOrderId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      purchasesOrder: null,
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
      ORDER_STATUS_CANCELED: ORDER_STATUS_CANCELED,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
      purchasesOrderDeliveryFormModalOpened: false,
    };
  },

  computed: {
    canCancelPurchasesOrder() {
      if (
        this.purchasesOrder.status === ORDER_STATUS_CANCELED ||
        this.purchasesOrder.status === ORDER_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.purchasesOrder.status === ORDER_STATUS_VALIDATED) {
        return true;
      }
      return false;
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "purchasesOrders/get",
          this.purchasesOrderId
        );
        this.purchasesOrder =
          this.$store.getters["purchasesOrders/purchasesOrder"];
        this.$emit("purchasesOrderLoaded", this.purchasesOrder);
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
