<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("PURCHASES_ORDERS.PURCHASES_ORDERS_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openPurchasesOrderCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_PURCHASES_ORDERS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("PURCHASES_ORDERS.ADD_PURCHASES_ORDER") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_PURCHASES_ORDERS)"
              :objectType="'purchases-orders'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <purchases-order-list-table
        @onViewPurchasesOrder="openPurchasesOrderViewModal"
        @onEditPurchasesOrder="openPurchasesOrderEditModal"
        @onDeletePurchasesOrder="deletePurchasesOrder"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewPurchasesOrderModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewPurchasesOrderModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_ORDER'"
          @onCloseModal="closePurchasesOrderModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PURCHASES_ORDERS.VIEW_PURCHASES_ORDER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="validatePurchasesOrder(openPurchasesOrder)"
                v-if="
                  openPurchasesOrderLoaded
                    ? openPurchasesOrder.items.length > 0 &&
                      openPurchasesOrder.status === ORDER_STATUS_DRAFT
                    : false
                "
                :title="$t('PURCHASES_ORDERS.VALIDATE')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-check-bold"></i>
                </span>
              </base-button>
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_ROLES)"
              >
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="
                    () => {
                      purchasesOrderInvoiceFormModalOpened = true;
                    }
                  "
                  v-if="
                    openPurchasesOrderLoaded
                      ? openPurchasesOrder.status == ORDER_STATUS_VALIDATED &&
                        openPurchasesOrder.invoicing_status !=
                          INVOICING_STATUS_INVOICED
                      : false
                  "
                  :title="$t('PURCHASES_ORDERS.ADD_INVOICE')"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-single-copy-04"></i>
                  </span>
                </base-button>
              </el-tooltip>

              <base-button
                type="primary"
                icon
                size="sm"
                @click="
                  () => {
                    purchasesOrderDeliveryFormModalOpened = true;
                  }
                "
                v-if="
                  openPurchasesOrderLoaded
                    ? openPurchasesOrder.status == ORDER_STATUS_VALIDATED &&
                      openPurchasesOrder.delivery_status !=
                        DELIVERY_STATUS_DELIVERED
                    : false
                "
                :title="$t('PURCHASES_ORDERS.ADD_DELIVERY')"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-truck"></i>
                </span>
              </base-button>

              <base-button
                type="danger"
                icon
                size="sm"
                @click="cancelPurchasesOrder(openPurchasesOrder)"
                v-if="canCancelPurchasesOrder"
                :title="$t('PURCHASES_ORDERS.CANCEL')"
              >
                <span class="btn-inner--icon">
                  <i class="fal fa-times"></i>
                </span>
              </base-button>

              <notification-subscription
                v-if="openPurchasesOrder"
                :objectType="'PurchasesOrders'"
                :objectId="openPurchasesOrder.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  v-if="
                    openPurchasesOrder.status === ORDER_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_EDIT_PURCHASES_ORDERS)
                  "
                  @click="openPurchasesOrderEditModal(openPurchasesOrder)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="delete"
                  v-if="
                    openPurchasesOrder.status === ORDER_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_DELETE_PURCHASES_ORDERS)
                  "
                  @click="deletePurchasesOrder(openPurchasesOrder)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePurchasesOrderModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-purchases-order-page
              v-if="openPurchasesOrder"
              :purchasesOrderId="openPurchasesOrder.id"
              @purchasesOrderLoaded="
                (purchasesOrder) => {
                  openPurchasesOrder = purchasesOrder;
                  openPurchasesOrderLoaded = true;
                }
              "
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditPurchasesOrderModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditPurchasesOrderModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_ORDER'"
          @onCloseModal="closePurchasesOrderModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PURCHASES_ORDERS.EDIT_PURCHASES_ORDER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button
                class="close"
                @click="openPurchasesOrderViewModal(openPurchasesOrder)"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-purchases-order-page
              v-if="openPurchasesOrder"
              :purchasesOrderId="openPurchasesOrder.id"
              @onViewPurchasesOrder="openPurchasesOrderViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddPurchasesOrderModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddPurchasesOrderModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_ORDER'"
          @onCloseModal="closePurchasesOrderModal"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("PURCHASES_ORDERS.ADD_PURCHASES_ORDER") }}
            </h1>
            <button class="close" @click="closePurchasesOrderModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-purchases-order-page
              @onViewPurchasesOrder="openPurchasesOrderViewModal"
            />
          </div>
        </div>
      </div>
    </div>

    <purchases-order-view-add-delivery-form
      v-if="
        openPurchasesOrderLoaded
          ? !openPurchasesOrder.deliveryItemsState.orderDelivered
          : false
      "
      :purchasesOrder="openPurchasesOrder"
      :showModal="purchasesOrderDeliveryFormModalOpened"
      @closePurchasesOrderDeliveryForm="
        () => {
          purchasesOrderDeliveryFormModalOpened = false;
        }
      "
    />

    <purchases-order-view-add-invoice-form
      v-if="
        openPurchasesOrderLoaded
          ? !openPurchasesOrder.invoicingItemsState.orderInvoiced
          : false
      "
      :purchasesOrder="openPurchasesOrder"
      :showModal="purchasesOrderInvoiceFormModalOpened"
      @closePurchasesOrderInvoiceForm="
        () => {
          purchasesOrderInvoiceFormModalOpened = false;
        }
      "
    />
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import {
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_DRAFT,
  ORDER_STATUS_VALIDATED,
  INVOICING_STATUS_INVOICED,
  DELIVERY_STATUS_DELIVERED,
} from "@/constants/orders";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import requestErrorMixin from "@/mixins/request-error-mixin";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PurchasesOrderListTable from "./partials/PurchasesOrderListTable.vue";
import EditPurchasesOrderPage from "./components/EditPurchasesOrderComponent.vue";
import AddPurchasesOrderPage from "./components/AddPurchasesOrderComponent.vue";
import ViewPurchasesOrderPage from "./components/ViewPurchasesOrderComponent.vue";
import PurchasesOrderViewAddDeliveryForm from "./partials/PurchasesOrderViewAddDeliveryForm.vue";
import PurchasesOrderViewAddInvoiceForm from "./partials/PurchasesOrderViewAddInvoiceForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PurchasesOrderListTable,
    NotificationSubscription,
    EditPurchasesOrderPage,
    AddPurchasesOrderPage,
    ViewPurchasesOrderPage,
    [Button.name]: Button,
    PurchasesOrderViewAddDeliveryForm,
    PurchasesOrderViewAddInvoiceForm,
  },

  mixins: [requestErrorMixin],

  computed: {
    canCancelPurchasesOrder() {
      if (!this.openPurchasesOrder) {
        return false;
      }
      if (
        this.openPurchasesOrder.status === ORDER_STATUS_CANCELED ||
        this.openPurchasesOrder.status === ORDER_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.openPurchasesOrder.status === ORDER_STATUS_VALIDATED) {
        return true;
      }
      return false;
    },
  },

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPurchasesOrderModalOpened = false;
    let isEditPurchasesOrderModalOpened = false;
    let isAddPurchasesOrderModalOpened = false;
    let openPurchasesOrder = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPurchasesOrderModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPurchasesOrderModalOpened = true;
      }
      openPurchasesOrder = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPurchasesOrderModalOpened = true;
    }
    return {
      isViewPurchasesOrderModalOpened: isViewPurchasesOrderModalOpened,
      isEditPurchasesOrderModalOpened: isEditPurchasesOrderModalOpened,
      isAddPurchasesOrderModalOpened: isAddPurchasesOrderModalOpened,
      openPurchasesOrder: openPurchasesOrder,
      openPurchasesOrderLoaded: false,
      renderKey: 1,
      ORDER_STATUS_CANCELED: ORDER_STATUS_CANCELED,
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
      INVOICING_STATUS_INVOICED: INVOICING_STATUS_INVOICED,
      DELIVERY_STATUS_DELIVERED: DELIVERY_STATUS_DELIVERED,
      purchasesOrderDeliveryFormModalOpened: false,
      purchasesOrderInvoiceFormModalOpened: false,
    };
  },

  methods: {
    openPurchasesOrderCreateModal() {
      this.closePurchasesOrderModal();
      this.isAddPurchasesOrderModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesOrders",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openPurchasesOrderViewModal(purchasesOrder, reRender = false) {
      this.closePurchasesOrderModal();
      this.openPurchasesOrder = purchasesOrder;
      this.isViewPurchasesOrderModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesOrders",
          query: { id: this.openPurchasesOrder.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openPurchasesOrderEditModal(purchasesOrder) {
      this.closePurchasesOrderModal();
      this.openPurchasesOrder = purchasesOrder;
      this.isEditPurchasesOrderModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesOrders",
          query: { id: this.openPurchasesOrder.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closePurchasesOrderModal() {
      this.isAddPurchasesOrderModalOpened = false;
      this.isViewPurchasesOrderModalOpened = false;
      this.isEditPurchasesOrderModalOpened = false;
      this.openPurchasesOrderLoaded = false;
      this.openPurchasesOrder = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesOrders",
          query: {},
        }).href
      );
    },

    async validatePurchasesOrder(purchasesOrder) {
      try {
        await this.$store.dispatch(
          "purchasesOrders/validate",
          purchasesOrder.id
        );
        this.openPurchasesOrder =
          this.$store.getters["purchasesOrders/purchasesOrder"];
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_VALIDATED"),
        });
        this.renderKey++;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelPurchasesOrder(purchasesOrder) {
      const confirmation = await swal.fire({
        title: this.$t("PURCHASES_ORDERS.CANCEL_PURCHASES_ORDER_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        await this.$store.dispatch("purchasesOrders/cancel", purchasesOrder.id);
        this.openPurchasesOrder =
          this.$store.getters["purchasesOrders/purchasesOrder"];
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_CANCELED"),
        });
        this.renderKey++;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deletePurchasesOrder(purchasesOrder) {
      const confirmation = await swal.fire({
        title: this.$t("PURCHASES_ORDERS.DELETE_THIS_PURCHASES_ORDER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "purchasesOrders/destroy",
            purchasesOrder.id
          );
          this.renderKey++;
          this.closePurchasesOrderModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
